<template>
    <BuyerInformation class="buyer-information__individual" :buyer-information="buyerInformation">
        <template #btn-section="{ userData, addressData, cityData }">
            <div class="btn-section__individual">
                <div class="order-kit__section">
                    <CRButton @click="$router.push({ name: 'individualOrderDelivery' })" pattern="outline">
                        <template v-slot:icon>
                            <ArrowSvg class="arrow-icon" />
                        </template>
                        {{ $t('buttons.back') }}
                    </CRButton>
                </div>
                <div class="order-kit__section footer_button">
                    <CRButton @click="toPaymentPage(userData, addressData, cityData)">
                        {{ $t('order.submit') }}
                    </CRButton>
                </div>
            </div>
        </template>
    </BuyerInformation>
</template>

<script>
    import BuyerInformation from '@/components/Order/BuyerInformation.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import ArrowSvg from '@/assets/icons/arrows/arrow-down.svg?inline';
    import { checkIsFormValid } from '@/helpers/BuyerInfoHelper';
    import { mapActions, mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';

    export default {
        name: 'BuyerInfo',
        components: { BuyerInformation, CRButton, ArrowSvg },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                checkIsFormValid,
            };
        },
        computed: {
            ...mapGetters('orderInformation', ['buyerInformation']),
        },
        methods: {
            ...mapActions('orderInformation', ['setBuyerInformation']),

            async toPaymentPage(userData, addressData, cityData) {
                const result = await this.v$.$validate();
                if (!result) {
                    this.$filters.toast(this.$t('errors.someFieldsAreNotFilledCorrectly'));
                    return;
                }

                this.setBuyerInformation({ userData, addressData, cityData });
                this.$router.push({ name: 'individualOrderSubmit' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .buyer-information__individual {
        @include media($lg) {
            margin: 0 auto;
            width: 575px;
        }
    }

    .btn-section {
        @include row-align-center-justify-between;
    }

    .btn-section__individual {
        button {
            margin-bottom: 20px;
        }

        .arrow-icon {
            transform: rotate(90deg);
        }

        .footer_button {
            @include row-align-center-justify-end;

            border-top: solid $grey-line 1px;
            padding-top: 10px;
        }
    }
</style>
